<template>
  <v-card
    color="element"
    class="rounded-lg"
  >
    <v-card-title class="title-text">{{ $t(`newDeals.productInfo.firstCard.title.${assetStatus}`) }}</v-card-title>
    <v-card-text class="defaultText--text mobile-text">
      <v-row>
        <v-col class="pb-0">
          {{ $t('newDeals.productInfo.firstCard.firstItem') }}
        </v-col>
        <v-col align="right" class="pb-0">
          {{ $n(product.subscriptionInformation.emissionVolume, 'float') }} €
        </v-col>
      </v-row>
      <v-row v-if="assetStatus !== 'Financed'">
        <v-col :class="['pr-0', assetStatus === 'Offered' && 'pb-0']">
          {{ $t('newDeals.productInfo.firstCard.secondItem') }}
        </v-col>
        <v-col align="right" :class="['pl-0', assetStatus === 'Offered' && 'pb-0']">
          {{ $n(investorSubscriptionInformation.minSubscriptionAmount, 'float') }} €
        </v-col>
      </v-row>
      <v-row v-if="assetStatus === 'Offered'">
        <v-col>
          {{ $t('newDeals.productInfo.firstCard.thirdItem') }}
        </v-col>
        <v-col align="right" cols="8">
          {{ $n(investorSubscriptionInformation.totalOfferedTokens) }} /
          {{ $n(investorSubscriptionInformation.totalTokenSupply) }} Token
        </v-col>
      </v-row>
      <ProgressBar
        v-if="['Offered', 'Financed'].includes(assetStatus)"
        :percentage="product.fundingPercentage * 100"
      />
      <v-btn
        v-if="assetStatus === 'Offered'"
        color="primary"
        block
        id="invest-btn"
        class="black--text mt-5"
        @click="$emit('invest')"
        :loading="investLoading"
        :disabled="investorSubscriptionInformation.pdpNotification !== null"
      >
        {{ $t('newDeals.productInfo.firstCard.button') }}
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'SubscriptionInfoCard',
  components: {
    ProgressBar: lazyLoad('components/Shared/ProgressBar'),
  },
  props: {
    investorSubscriptionInformation: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    investLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    assetStatus() {
      return this.product.assetStatus;
    },
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .title-text {
    font-size: 16px;
  }
  .mobile-text{
    font-size: 10px;
  }
}
</style>
